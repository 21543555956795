import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }
//style diagram content view
  .img-content-size{
    figure.image{
      //width: 600px;
      //height: 550px;
      overflow: auto;
    }
  }
  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${'' /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

body .ant-form-item-label {
  text-align: left;
}
body textarea.ant-input {
  min-height: 100px;
  resize: none;
}

body .textarea-editable{
  textarea.ant-input{
    min-height: auto;
  }
}

.text-right {
  text-align: right; }
  @media (max-width: 575px) {
    .text-right {
      text-align: center; 
  }
}
.texto-center {
  text-align: center;
}

/*form image*/
.form-item-image {
  height: auto;
  width: 150px;
  max-height: 150px;
}
.form-item-space{
  .evTtom.ant-btn{
    margin-top: 30px;
  }
}
/*ck-editor styles*/
div.ck-editor__editable {
  min-height: 150px;
}

.ck-content p {
  margin-bottom: 15px;
}

.profile_picture {
  width: 60px;
  height: 60px; 
}

.isoTableSearchBox {
  display: flex;
  margin-bottom: 15px;
}
.minus-circle-custom.anticon svg {
  width: 14px;
  height: 14px;
}

.content-image-icon
{
  //text-align:left;
  .content-image-icon-background
  {
    background-color:#dfdfdf;
    padding:15px 15px;
    display:inline-block;
    img{
      max-width:100%;
      height:auto;
      min-height:45px;
      max-height:130px;
    }
  }
}

.content-image-background
{
  //text-align:left;
  .content-image-background-background
  {
    display:inline-block;
    background-color:#fafafa;
    padding:15px 10px;
    max-width:70%;
    img{
      max-width:100%;
      height:auto;
    }
    @media (max-width: 767px) 
    {
      max-width:100%;
    }
  }
}
.progress-table td a span svg{
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -71%);
}
.progress-table td{
  vertical-align: top;
}

.image-menu{
  .ant-form-item-control-input-content{
    background-color:#0D081E;
    text-align:center;
  }
}  

.no-display.undefined{
  display:none;
}

.display.undefined{
  display:block;
}
.divider-custom.divider-custom{
  border-left-color: #cccccc;
  margin-right: 20px;
  margin-left: 20px;
  height: 1em;
}
.ant-btn.ant-btn-primary.wrap-button{
  white-space: break-spaces;
  height: unset;
  min-height: 36px;
}
.layout-vertical{
  .ant-row.ant-form-item-row{
    display: block;
  }
}
.progress-text {
  text-align: center;
  margin-bottom: -5;
}
.hide-table-rows {
  display: none; 
}
.show-table-rows {
  display: table-cell; 
}
.diagram-score {
  display: flex;
  justify-content: space-between;
  .ant-tag{
    font-size: 10px;
    margin: 0;
    padding-right: 5px;
    padding-left: 5px;
    line-height: 14px;
  }
}
.diagram-table{
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 3px 3px !important;
  }
}

.ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-small.progress-percent{
  .ant-progress-outer{
    margin-right: calc(-2em - 20px);
    padding-right: calc(2em + 20px);
  }
}

.score-form{
  width: 100%;
}

.row-space-between{
  display: flex;
  justify-content:space-between;
}

.lessons-border{
  border: 1px solid;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  border-color: #f0f0f0;
}

 .data-item{
  margin-left: 8px;
  margin-right: 8px;
   .data-heading{
    margin-bottom: 8px;
    background: #f0f0f0;
    padding: 5px;
  }
 }
  .company-form {
    .ant-form-item {
      width: 700px;
    }
  }
.styles-form-add-task{
  .ant-form-item-label{
    font-weight: 600;
  }
  .ant-form-item{
    margin-bottom: 30px;
  }
}
 
`;

export default GlobalStyles;
